//import $ from 'jquery';
export default{
    name: "Header",
    data(){
        return{
            name:'',
            email:''
        }
    },
    mounted() {
        
        this.name = localStorage.getItem("firstname") + " " +localStorage.getItem("lastname");
        this.email = localStorage.getItem("email");

        // $("#menu li").each(function(index, row){
        //     console.log(index, index);
        //     if($(row).children('ul').length > 0)
        //     {
        //         $(row).children('ul').addClass('mm-collapse');
        //     }
        // });


        // $("#menu li").click(function(){
        //     if($(this).children("ul").length > 0)
        //     {
        //         if($(this).children("ul").hasClass('mm-show'))
        //         {
        //             $(this).children("ul").removeClass('mm-show');
        //             $(this).children('a').attr('aria-expanded',false);
        //         }
        //         else
        //         {
        //             $(this).addClass('mm-active');
        //             $(this).children("ul").addClass('mm-show');
        //         }
        //     }
        //     return false;
        // });

        window.$("#menu").metisMenu();


        // $("#menu > li > ul > li").click(function(){
        //     //alert('ssss');

        //     if($(this).children("ul").length > 0)
        //     {
        //         if($(this).children("ul").hasClass('mm-show'))
        //         {
        //             $(this).children("ul").removeClass('mm-show');
        //             $(this).children('a').attr('aria-expanded',false);
        //         }
        //         else
        //         {
        //             $('ul').removeClass('mm-show');
        //             $('li').removeClass('mm-active');
        //             $(this).parent().addClass('mm-collapse');
        //             $(this).parent().addClass('mm-show');
        //             $(this).addClass('mm-active');
        //             $(this).children("ul").addClass('mm-show');
        //         }
        //     }
        //     return false;
        // });
    },
    methods:{
        logout(){
            localStorage.clear();
            this.$router.push('/login');
            window.location.reload(true);
        },
    },
  
}